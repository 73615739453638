import Card from './Card';
import Grid from './Grid';
import Stats from './Stats';
import Loader from './Loader';
import Button from './Button';
import Sidebar from './Sidebar';
import Stepper from './Stepper';
import Tooltip from './Tooltip';
import {LineChart} from './Chart';
import AppDrawer from './AppDrawer';
import MUISelect from './MUISelect';
import SendEmail from './SendEmail';
import AppHeader from './AppHeader';
import ChipStack from './ChipStack';
import ContentBox from './ContentBox';
import TextEditor from './TextEditor';
import ThemeSwitch from './ThemeSwitch';
import DataGridPro from './DataGridPro';
import MUITextField from './MUITextField';
import FileUploader from './FileUploader';
import CustomSwitch from './CustomSwitch';
import CustomDialog from './CustomDialog';
import AutoComplete from './AutoComplete';
import MetrixSummary from './MetrixSummary';
import DebouncedInput from './DebouncedInput';
import MaskInputField from './MaskInputField';
import StatsWithIcon from './StatsWithIcon';
import TimeLineFilter from './TimeLineFilter';
import ImageUploader from './ImageUploader';
import ProfilePreview from './ProfilePreview';
import BackgroundPaper from './BackgroundPaper';
import {createTheme} from '@mui/material/styles';
import RequestErrorLoader from './RequestErrorLoader';
import {Accordion, AccordionTheme} from './Accordion';
import SearchResultsCounter from './SearchResultsCounter';
import {Tab, TabList, TabContext, TabPanel} from './Tabs';
import {themeColorMode} from './utils';
import AppLayout from './AppLayout';
import ProfileMenu from './ProfileMenu';
import NoAccess from './NoAccess';
import ImageCropper from './Cropper/ImageCropper';
import RangeSlider from './RangeSlider';
import CareerCard from './CareerCard';
import BarChart from './BarChart';
import NoResults from './NoResults';
import StudentResumePreview from './StudentResumePreview';

const componentsTheme = createTheme({
  components: {
    MuiAccordion: AccordionTheme.components.MuiAccordion,
    MuiAccordionDetails: AccordionTheme.components.MuiAccordionDetails,
    MuiAccordionSummary: AccordionTheme.components.MuiAccordionSummary,
  },
});

export {
  Tab,
  Card,
  Grid,
  Stats,
  Button,
  Loader,
  Sidebar,
  TabList,
  Tooltip,
  Stepper,
  NoAccess,
  TabPanel,
  LineChart,
  Accordion,
  MUISelect,
  SendEmail,
  AppLayout,
  AppDrawer,
  AppHeader,
  ChipStack,
  TextEditor,
  TabContext,
  ContentBox,
  ThemeSwitch,
  ProfileMenu,
  DataGridPro,
  MUITextField,
  FileUploader,
  AutoComplete,
  CustomSwitch,
  MetrixSummary,
  CustomDialog,
  StatsWithIcon,
  DebouncedInput,
  MaskInputField,
  TimeLineFilter,
  ImageUploader,
  ProfilePreview,
  componentsTheme,
  BackgroundPaper,
  RequestErrorLoader,
  SearchResultsCounter,
  themeColorMode,
  ImageCropper,
  RangeSlider,
  CareerCard,
  BarChart,
  NoResults,
  StudentResumePreview,
};
