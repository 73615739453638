import {useColor} from 'mui-core/hooks';
import {InfoCircle} from 'sharedResources/assets/icons';
import {themeColorMode, Tooltip} from 'mui-core';
import {Divider, Box, Stack, styled, Typography, useTheme} from '@mui/material';

const CustomStack = styled(Stack)(
  ({theme, index, lengthOfData, borderBottomColor}) => ({
    flexDirection: 'row',
    alignItems: 'center',
    flex: index > 3 ? 1 : 'unset',
    paddingBottom: lengthOfData > 4 && 24,
    justifyContent: 'space-between',
    borderBottom: lengthOfData > 4 && borderBottomColor,
    minWidth: lengthOfData > 4 ? '25%' : `${100 / lengthOfData}%`,
    [theme.breakpoints.down('lg')]: {
      flex: 'unset',
      width: '50%',
      borderBottom:
        index !== lengthOfData - 1 &&
        index !== lengthOfData - 2 &&
        borderBottomColor,
      paddingBottom:
        index !== lengthOfData - 1 && index !== lengthOfData - 2 && 24,
    },
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      width: '100%',
      paddingBottom: 0,
      borderBottom: index !== lengthOfData - 1 && borderBottomColor,
    },
  })
);

export default function MetrixSummary({
  title,
  matrixData,
  tooltipTitle,
  tooltipTitleDesc,
  tooltipTitleInfoHeader,
}) {
  const theme = useTheme();
  const borderBottomColor =
    theme.palette.mode === 'dark'
      ? '1px solid rgba(255, 255, 255, 0.2)'
      : '1px solid rgba(0, 0, 0, 0.07)';

  return (
    <Box my={{xs: 2, md: 5}}>
      <Stack
        py={2}
        px={3}
        gap={1}
        direction="row"
        alignItems="flex-start"
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
        bgcolor={themeColorMode(
          theme,
          theme.palette.backgroundL1.main,
          theme.palette.darkGray.main
        )}>
        <Typography
          variant="text-lg-bold"
          color={useColor(
            theme.palette.greyLight800.main,
            theme.palette.background.white
          )}>
          {title}
        </Typography>
        <Tooltip
          tooltipData={
            <Stack
              sx={{
                gap: 2,
                alignItems: 'flex-start',
              }}>
              <Stack
                direction="row"
                sx={{
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                <InfoCircle
                  color="white"
                  fontSize="28px"
                  background={theme.palette.primary.main}
                />
                <Typography
                  component="h4"
                  variant="title-lg-bold"
                  color={useColor(
                    'text.primary',
                    theme.palette.background.white
                  )}>
                  {tooltipTitle}
                </Typography>
              </Stack>
              <Typography
                component="p"
                variant="text-md-bold"
                color={useColor(
                  theme.palette.grey800.main,
                  theme.palette.background.white
                )}>
                {tooltipTitleInfoHeader}
              </Typography>
              <Typography
                component="p"
                variant="text-sm-regular"
                color={useColor(
                  theme.palette.grey800.main,
                  theme.palette.background.white
                )}
                dangerouslySetInnerHTML={{
                  __html: tooltipTitleDesc,
                }}
              />
            </Stack>
          }>
          <Box>
            <InfoCircle
              color={theme.palette.background.white}
              background={theme.palette.primary.main}
            />
          </Box>
        </Tooltip>
      </Stack>
      <Stack
        p={3}
        id="matrix"
        rowGap={3}
        direction="row"
        flexWrap="wrap"
        sx={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
        bgcolor={useColor(
          theme.palette.background.white,
          'rgba(255, 255, 255, 0.005)'
        )}
        boxShadow={useColor(
          '0px 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)',
          '2px 2px 4px 1px rgba(255, 255, 255, 0.010),0px 1px 1px 1px rgba(255, 255, 255, 0.10)'
        )}>
        {matrixData &&
          matrixData?.length &&
          matrixData?.map(({metrixTitle, metrixCount}, index) => (
            <CustomStack
              index={index}
              borderBottomColor={borderBottomColor}
              lengthOfData={matrixData?.length}>
              <Stack width="100%">
                <Typography
                  variant="text-sm-regular"
                  textTransform="capitalize"
                  color={themeColorMode(
                    theme,
                    'rgba(0, 0, 0, 0.50)',
                    theme.palette.background.white
                  )}>
                  {metrixTitle}
                </Typography>
                <Typography
                  variant="header-md-thin"
                  color={themeColorMode(
                    theme,
                    theme.palette.text.primary,
                    theme.palette.background.white
                  )}>
                  {metrixCount}
                </Typography>
              </Stack>
              <Divider
                variant="middle"
                orientation="vertical"
                bgcolor={themeColorMode(
                  theme,
                  'rgba(0, 0, 0, 0.2',
                  theme.palette.background.white
                )}
                sx={{
                  mx: 2,
                  my: 2,
                  width: '1px',
                  height: '100%',
                  display: {
                    xs: 'none',
                    md: index % 2 === 0 && index !== 6 ? 'block' : 'none',
                    lg: index === 3 || index === 6 ? 'none' : 'block',
                  },
                }}
              />
            </CustomStack>
          ))}
      </Stack>
    </Box>
  );
}
