import {memo, useEffect, useState, useCallback} from 'react';
// components
import {
  gridClasses,
  DataGridPro,
  GridFilterPanel,
  GridToolbarContainer,
  // GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import {Button, SearchResultsCounter, Tooltip} from 'mui-core';
import {Box, Chip, Stack, Typography, useTheme} from '@mui/material';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
// utils
import {useDrawer} from 'core/hooks';
import {PropTypes} from 'prop-types';
import isObject from 'lodash/isObject';
import {isEmpty} from 'mui-core/utils';
import {alpha, styled} from '@mui/material/styles';
import {useColor, useEmailSender} from 'mui-core/hooks';
import {
  Download02,
  InfoCircle,
  MessageChatCircle,
} from 'sharedResources/assets/icons';
import settingsData from 'data/settings.json';

const {selectedBtnLabel, sendMessageBtnText} = settingsData || {};

export const RenderToolTip = ({tooltip, recordLength, showBadgeCount}) => {
  const theme = useTheme();
  const {title, subTitle, description} = tooltip || {};

  return (
    <Stack direction="row" sx={{m: 2}} spacing={1}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography
          component="h3"
          variant="text-lg-bold"
          color={useColor('text.primary', theme.palette.background.white)}>
          {title}
        </Typography>
        {showBadgeCount && (
          <Chip
            title={recordLength || 0}
            label={recordLength || 0}
            sx={theme => ({
              height: '24px',
              borderRadius: '8px',
              '& .MuiChip-label': {
                px: '6px',
                pt: '3px',
                fontSize: '13px',
                lineHeight: '18px',
                fontFamily: 'Inter',
                color: theme.palette.greyLight800.main,
                ...theme.applyStyles('dark', {
                  color: theme.palette.background.white,
                }),
              },
            })}
          />
        )}
      </Stack>
      <Tooltip
        tooltipData={
          <Stack
            sx={{
              gap: 2,
              alignItems: 'flex-start',
            }}>
            <Stack
              direction="row"
              sx={{
                gap: 1,
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <InfoCircle
                fontSize="30px"
                color={theme.palette.background.white}
                background={theme.palette.primary.main}
              />
              <Typography
                component="h4"
                variant="title-lg-bold"
                color={useColor(
                  'text.primary',
                  theme.palette.background.white
                )}>
                {title}
              </Typography>
            </Stack>
            <Typography
              component="p"
              variant="text-md-bold"
              color={useColor(
                theme.palette.grey800.main,
                theme.palette.background.white
              )}>
              {subTitle}
            </Typography>
            <Typography
              component="p"
              variant="text-sm-regular"
              color={useColor(
                theme.palette.grey800.main,
                theme.palette.background.white
              )}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Stack>
        }>
        <Box>
          <InfoCircle
            color={theme.palette.background.white}
            background={theme.palette.primary.main}
          />
        </Box>
      </Tooltip>
    </Stack>
  );
};

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPro)(
  ({theme, striped, checkboxSelection}) => ({
    fontFamily: 'Inter',
    '& p': {
      marginTop: 'initial',
      marginBottom: 'initial',
    },
    '& .MuiDataGrid-toolbarContainer > button': {
      fontSize: '14px',
    },
    [`& .MuiSelect-standard,
      .MuiDataGrid-rowCount,
      .MuiTablePagination-selectLabel,
      .MuiTablePagination-displayedRows,
      .MuiIconButton-root:not(.Mui-disabled)`]: {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.background.white
          : theme.palette.dialogDarkBG.contrastText,
    },
    [`& .MuiDataGrid-columnHeaderCheckbox, 
       .MuiDataGrid-container--bottom [role="row"], 
       .MuiDataGrid-container--top [role="row"]`]: {
      fontSize: '11px',
      color: theme.palette.grey[800],
      backgroundColor: `${theme.palette.grey[300]}`,
      ...theme.applyStyles('dark', {
        color: 'white',
        backgroundColor: theme.palette.darkGray.main,
      }),
    },
    '& .MuiDataGrid-virtualScroller': {
      fontSize: '14px',
    },
    '& .first-header': {
      paddingLeft: checkboxSelection ? '12px' : '32px',
    },
    '& .first-header-cell': {
      paddingLeft: checkboxSelection ? '12px' : '32px',
    },
    [`& .MuiDataGrid-columnHeader[data-field="actions"]
          > .MuiDataGrid-columnSeparator, 
        .MuiDataGrid-columnHeader[data-field="__detail_panel_toggle__"]
          > .MuiDataGrid-columnSeparator`]: {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader > .MuiDataGrid-columnSeparator': {
      color: theme.palette.midGray.main,
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
      // outline: 'none !important',
    },
    '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '11px',
      color:
        theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.text.contrastText,
    },
    [`& .MuiDataGrid-filler--pinnedLeft,
       .MuiDataGrid-cell--withRightBorder`]: {
      borderRight: 'none',
    },
    '& .MuiDataGrid-detailPanel': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.background.paper,
    },
    [`& .${gridClasses.row}.odd`]: striped
      ? {
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.grey[50]
              : theme.palette.dialogDarkBG.main,
          '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.midGray.main, ODD_OPACITY),
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            backgroundColor: alpha(
              theme.palette.midGray.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            '&:hover, &.Mui-hovered': {
              backgroundColor: alpha(
                theme.palette.midGray.main,
                ODD_OPACITY +
                  theme.palette.action.selectedOpacity +
                  theme.palette.action.hoverOpacity
              ),
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: alpha(
                  theme.palette.midGray.main,
                  ODD_OPACITY + theme.palette.action.selectedOpacity
                ),
              },
            },
          },
        }
      : {},
  })
);

const StyledGridOverlay = styled(Box)(() => ({
  height: 340,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FilterPanelContainer = styled(Stack)(({theme}) => ({
  padding: '8px',
  fontFamily: 'Inter !important',
  '& .MuiButton-root': {
    fontFamily: 'Inter !important',
    fontSize: '11px',
  },
  '& .MuiInput-root': {
    fontFamily: 'Inter !important',
    fontSize: '11px',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Inter !important',
    fontSize: '11px',
  },
  '& .MuiDataGrid-filterForm': {
    alignItems: 'flex-end',
    '& .MuiDataGrid-filterFormLogicOperatorInput': {
      display: 'none',
    },
    '& .MuiDataGrid-filterFormValueInput .MuiFormControl-root ': {
      width: '100%',
    },
    '& input': {
      height: '30px',
      border: 'none',
      font: 'inherit',
      fontSize: 'initial',
      padding: '0px !important',
      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
    },
  },
}));

const DataGrid = ({
  sx = {},
  loading,
  getRowId,
  rows = [],
  sortModel,
  initialState,
  columns = [],
  filters = [],
  tooltip = {},
  message = {},
  pageSize = 10,
  onApplyFilter,
  // onFilterChange,
  height = 'auto',
  autosizeOptions,
  isRowSelectable,
  recordCount = {},
  showCount = true,
  onSortModelChange,
  onPageModelChange,
  pagination = true,
  autoHeight = false,
  hideFooter = false,
  exportSelectedRows,
  showToolbar = true,
  paginationModel = {
    page: 0,
    pageSize: 10,
  },
  getDetailPanelHeight,
  hideBoxShadow = false,
  hideGridExport = true,
  hideGridFilter = true,
  getDetailPanelContent,
  hideColumnMenu = true,
  filterMode = 'server',
  sortingMode = 'server',
  showBadgeCount = false,
  hideOuterBorder = true,
  hideQuickFilter = true,
  autosizeOnMount = true,
  rowSelectionModel = [],
  showStripedGrid = false,
  disableAutosize = false,
  paginationMode = 'server',
  onRowSelectionModelChange,
  checkboxSelection = false,
  disableRowSelection = true,
  disableColumnResize = false,
  disableColumnFilter = false,
  disableColumnPinning = false,
  disableColumnSelector = false,
  hideFooterPagination = false,
  noResultsMsg = 'No Results Found',
  pageSizeOptions = [10, 15, 20, 25],
  keepNonExistentRowsSelected = true,
  hideFooterSelectedRowCount = false,
}) => {
  const theme = useTheme();
  const containerStyles = {};
  const mailer = useEmailSender();
  const {
    APICall,
    usersToMail = [],
    emailToId = true,
    showMessage = true,
  } = message;
  const {totalRowCount = 0, totalResults} = recordCount;
  const [gridFilters, setGridFilters] = useState(filters);
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  // switching total_count to undefined during loading resets the page to zero.
  // To avoid this problem, we are keeping the previous value of rowCount while loading
  useEffect(
    () => {
      setRowCountState(prevRowCountState =>
        totalRowCount !== undefined ? totalRowCount : prevRowCountState
      );
    },
    [totalRowCount]
  );

  const sendEmail = () => {
    if (rowSelectionModel?.length > 0) {
      const users = usersToMail.filter(user => {
        const idx = rowSelectionModel.findIndex(
          s => (emailToId ? user.id : user.uuid) === s
        );
        if (idx !== -1) {
          return true;
        }
        return false;
      });
      mailer.sendMail(
        users.map(item => ({
          ...item,
          user_account: emailToId ? item?.id : item?.uuid,
        })),
        () => APICall()
      );
    }
  };

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <ContentPasteOffIcon fontSize="large" />
        <Box sx={{mt: 2}}>
          <Typography component="p" variant="text-sm-regular">
            {noResultsMsg}
          </Typography>
        </Box>
      </StyledGridOverlay>
    );
  }

  function CustomToolbar() {
    return (
      <Stack
        direction="row"
        sx={{
          p: 0.5,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          {!hideGridFilter && <GridToolbarFilterButton />}
          {/* {!hideGridExport && <GridToolbarExport />} */}
          {!hideGridExport && (
            <Button
              onClick={exportSelectedRows}
              startIcon={
                <Download02
                  fontSize="15px"
                  color={
                    rowSelectionModel.length === 0
                      ? theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.3)'
                        : 'rgba(0, 0, 0, 0.26)'
                      : 'rgb(99, 91, 255)'
                  }
                />
              }
              disabled={rowSelectionModel.length === 0}>
              <Typography variant="text-xs-regular">
                Export Selection
              </Typography>
            </Button>
          )}
        </GridToolbarContainer>
        {!hideQuickFilter && (
          <GridToolbarQuickFilter
            debounceMs={600}
            quickFilterParser={searchInput =>
              searchInput
                .split(',')
                .map(value => value.trim())
                .filter(value => value !== '')
            }
          />
        )}
        <Stack
          sx={{mr: 2}}
          spacing={{xs: 1, md: 2}}
          direction={{xs: 'column', md: 'row'}}
          alignItems={{xs: 'flex-end', md: 'center'}}>
          {showCount && !showBadgeCount && (
            <SearchResultsCounter
              request={loading}
              totalResults={totalResults}
              currentLength={totalRowCount}
            />
          )}
          {showMessage && (
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              {rowSelectionModel &&
                Array.isArray(rowSelectionModel) &&
                rowSelectionModel?.length > 0 && (
                  <Typography variant="text-xs-regular">
                    <b>{rowSelectionModel?.length}</b> {selectedBtnLabel}
                  </Typography>
                )}
              <Button
                size="small"
                variant="outlined"
                onClick={sendEmail}
                startIcon={
                  <MessageChatCircle
                    color={
                      rowSelectionModel?.length === 0
                        ? theme.palette.grayBorder.main
                        : theme.palette.primary.main
                    }
                  />
                }
                disabled={rowSelectionModel?.length === 0}>
                {sendMessageBtnText}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }

  function CustomFilterPanel(props) {
    const {onApply, onCancel, showServerInteractiveButtons} = props;
    return (
      <FilterPanelContainer direction="column" position="relative">
        <GridFilterPanel {...props} />
        {showServerInteractiveButtons && (
          <Stack
            spacing={2}
            direction="row"
            sx={{
              right: 16,
              bottom: 16,
              alignItems: 'center',
              position: 'absolute',
              justifyContent: 'flex-end',
            }}>
            <Button variant="outlined" onClick={onCancel}>
              Clear all
            </Button>
            <Button variant="contained" onClick={onApply}>
              Apply
            </Button>
          </Stack>
        )}
      </FilterPanelContainer>
    );
  }

  const onApplyBtnClick = () => {
    onApplyFilter(gridFilters);
  };

  const onCancelBtnClick = () => {
    setGridFilters([]);
    onApplyFilter([], true);
  };

  const onFilterChange = useCallback(filterModel => {
    setGridFilters(filterModel.items);
  }, []);

  const filterColumns = ({field, columns, currentFilters}) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map(({field}) => field);
    return columns
      .filter(
        colDef =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map(column => column.field);
  };
  const getColumnForNewFilter = ({currentFilters, columns}) => {
    const filteredFields = currentFilters?.map(({field}) => field);
    const columnForNewFilter = columns
      .filter(
        colDef => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find(colDef => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  if (height) containerStyles.height = `${height}px`;

  const [openMenuBar] = useDrawer();
  const DataGridMaxWidth = openMenuBar
    ? `calc(100vw - 280px - 96px) !important`
    : `calc(100vw - 65px - 96px) !important`;

  return (
    <>
      {isObject(tooltip) && !isEmpty(tooltip) && (
        <RenderToolTip
          tooltip={tooltip}
          recordLength={totalRowCount}
          showBadgeCount={showBadgeCount}
        />
      )}
      <Box
        sx={{
          ...containerStyles,
          width: '100%',
          maxWidth: DataGridMaxWidth,
          '& .MuiInput-root': {
            fontFamily: 'Inter',
          },
          '& .MuiDataGrid-root': {
            fontFamily: 'Inter',
            '& .MuiTypography-root': {
              fontFamily: 'Inter !important',
            },
            '& .MuiButton-root': {
              fontFamily: 'Inter !important',
              fontSize: '11px',
            },
            '& .MuiDataGrid-footerContainer': {
              fontFamily: 'Inter',
              '& .MuiTablePagination-selectLabel': {
                fontFamily: 'Inter',
              },
              '& .MuiTablePagination-select': {
                fontFamily: 'Inter',
                fontSize: '12px',
              },
              '& .MuiTablePagination-displayedRows': {
                fontFamily: 'Inter',
              },
            },
          },
        }}>
        <StripedDataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          getRowId={getRowId}
          sortModel={sortModel}
          disableDensitySelector
          filterDebounceMs={50}
          pagination={pagination}
          filterMode={filterMode}
          autoHeight={autoHeight}
          hideFooter={hideFooter}
          rowCount={rowCountState}
          sortingMode={sortingMode}
          striped={showStripedGrid}
          paginationMode={paginationMode}
          disableAutosize={disableAutosize}
          autosizeOnMount={autosizeOnMount}
          paginationModel={paginationModel}
          pageSizeOptions={pageSizeOptions}
          isRowSelectable={isRowSelectable}
          disableColumnMenu={hideColumnMenu}
          filterModel={{items: gridFilters}}
          onFilterModelChange={onFilterChange}
          checkboxSelection={checkboxSelection}
          rowSelectionModel={rowSelectionModel}
          onSortModelChange={onSortModelChange}
          disableColumnFilter={disableColumnFilter}
          disableColumnResize={disableColumnResize}
          experimentalFeatures={{lazyLoading: true}}
          disableColumnPinning={disableColumnPinning}
          onPaginationModelChange={onPageModelChange}
          hideFooterPagination={hideFooterPagination}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          disableColumnSelector={disableColumnSelector}
          disableRowSelectionOnClick={disableRowSelection}
          sx={[
            {
              boxShadow: hideBoxShadow ? 0 : 2,
              ...sx,
            },
            hideOuterBorder
              ? {
                  borderWidth: 0,
                }
              : {
                  borderWidth: 2,
                },
          ]}
          onRowSelectionModelChange={onRowSelectionModelChange}
          hideFooterSelectedRowCount={hideFooterSelectedRowCount}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          autosizeOptions={{
            expand: true,
            includeHeaders: true,
            ...autosizeOptions,
          }}
          initialState={
            initialState
              ? {
                  ...initialState,
                  pinnedColumns: {
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                  },
                }
              : {
                  pagination: {paginationModel: {pageSize: pageSize, page: 0}},
                  pinnedColumns: {
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                  },
                }
          }
          slots={{
            columnMenuColumnsItem: null,
            noRowsOverlay: memo(CustomNoRowsOverlay),
            noResultsOverlay: memo(CustomNoRowsOverlay),
            toolbar: showToolbar ? memo(CustomToolbar) : null,
            filterPanel: props => (
              <CustomFilterPanel
                {...props}
                onApply={onApplyBtnClick}
                onCancel={onCancelBtnClick}
                showServerInteractiveButtons={filterMode === 'server'}
              />
            ),
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
            filterPanel: {
              filterFormProps: {
                filterColumns,
                valueInputProps: {
                  InputComponentProps: {
                    disabled: loading,
                  },
                },
                multiFilterOperator: 'and',
                showMultiFilterOperators: false,
              },
              getColumnForNewFilter,
              disableRemoveAllButton: filterMode === 'server',
            },
          }}
          getRowClassName={itm =>
            itm.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </Box>
    </>
  );
};

DataGrid.propTypes = {
  sx: PropTypes.object,
  rows: PropTypes.array,
  loading: PropTypes.bool,
  filters: PropTypes.array,
  height: PropTypes.string,
  columns: PropTypes.array,
  getRowId: PropTypes.string,
  pageSize: PropTypes.number,
  autoHeight: PropTypes.bool,
  hideFooter: PropTypes.bool,
  pagination: PropTypes.bool,
  sortModel: PropTypes.array,
  showToolbar: PropTypes.bool,
  hideBoxShadow: PropTypes.bool,
  filterMode: PropTypes.string,
  sortingMode: PropTypes.string,
  onApplyFilter: PropTypes.func,
  noResultsMsg: PropTypes.string,
  hideColumnMenu: PropTypes.bool,
  hideGridExport: PropTypes.bool,
  hideGridFilter: PropTypes.bool,
  // onFilterChange: PropTypes.func,
  initialState: PropTypes.object,
  autosizeOnMount: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  showStripedGrid: PropTypes.bool,
  hideQuickFilter: PropTypes.bool,
  hideOuterBorder: PropTypes.bool,
  paginationMode: PropTypes.string,
  pageSizeOptions: PropTypes.array,
  onPageModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  paginationModel: PropTypes.object,
  autosizeOptions: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  rowSelectionModel: PropTypes.array,
  disableRowSelection: PropTypes.bool,
  disableColumnResize: PropTypes.bool,
  disableColumnFilter: PropTypes.bool,
  disableColumnPinning: PropTypes.bool,
  hideFooterPagination: PropTypes.bool,
  getDetailPanelHeight: PropTypes.func,
  getDetailPanelContent: PropTypes.func,
  disableColumnSelector: PropTypes.bool,
  onRowSelectionModelChange: PropTypes.func,
  hideFooterSelectedRowCount: PropTypes.bool,
  keepNonExistentRowsSelected: PropTypes.bool,
};

export default memo(DataGrid);
