import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'mui-core/utils';
import {Box, Stack, Typography} from '@mui/material';

const IconWrapper = styled(Box)(() => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '35px',
  justifyContent: 'center',
  background: 'linear-gradient(#6DDCFF, #7F60F9)',
  [`& div`]: {
    p: '8px',
    width: '38px',
    height: '38px',
    display: 'flex',
    borderRadius: '35px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
}));

const StatsWithIcon = props => {
  const {data = []} = props;
  const formatCount = count => {
    if (count === 0) {
      return '0';
    }
    try {
      return numberInUSFormat(count, {minimumIntegerDigits: 2});
    } catch (err) {
      return 0;
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        gap: 3,
        flexGrow: 1,
        flexWrap: 'wrap',
        margin: '24px 0px',
      }}>
      {data &&
        data.map(({title = '', count = '0', icon}, index) => (
          <Stack
            key={index}
            spacing={2}
            direction="row"
            sx={{alignItems: 'flex-start'}}>
            <IconWrapper>
              <div>{icon}</div>
            </IconWrapper>
            <Stack sx={{minWidth: 60}}>
              <Typography
                variant="header-md-thin"
                sx={{
                  lineHeight: 1,
                  color: 'text.primary',
                }}>
                {formatCount(count)}
              </Typography>
              <Typography
                variant="text-md-regular"
                sx={{
                  overflow: 'hidden',
                  color: 'text.primary',
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                  ' -webkit-line-clamp': 2,
                  '-webkit-box-orient': 'vertical',
                }}>
                {title}
              </Typography>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

StatsWithIcon.propTypes = {
  data: PropTypes.array,
};

export default React.memo(StatsWithIcon);
