import {combineReducers} from 'redux';
import enrolledList from './enrolledList';
import goSurveyResults from './goSurveyResult';
import enrolledProgram from './enrolledProgram';
import programEnrollments from './programEnrollments';
import careerFavoritesList from './careerFavoritesList';
import programFavoritesList from './programFavoritesList';
import recruitmentInsightMetrics from './recruitmentInsightMetrics';

export const talentInsightsReducer = combineReducers({
  enrolledList: enrolledList,
  goSurveyResults: goSurveyResults,
  enrolledProgram: enrolledProgram,
  programEnrollments: programEnrollments,
  careerFavoritesList: careerFavoritesList,
  programFavoritesList: programFavoritesList,
  recruitmentInsightMetrics: recruitmentInsightMetrics,
});
