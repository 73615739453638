import {Stack, useMediaQuery, Typography} from '@mui/material';
// utils
import {PropTypes} from 'prop-types';
import {useColor} from 'mui-core/hooks';
import {useTheme} from '@mui/material/styles';
import {numberInUSFormat} from 'mui-core/utils';
import {textloading} from 'sharedResources/assets/images';
import settingsData from 'data/settings.json';

const {showingOfResultsFor: {showingTxt, resultsTxt, ofTxt, forTxt} = {}} =
  settingsData || {};

const SearchResultsCounter = ({
  request,
  forTitle,
  totalResults,
  currentLength,
}) => {
  let formattedTotalResults =
    (totalResults && numberInUSFormat(totalResults)) || 0;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const color = useColor(
    theme.palette.grey.main,
    theme.palette.grey.contrastText
  );
  const currentData = (
    <span>
      {request ? (
        <img
          alt="loader"
          height="6px"
          width="20px"
          src={textloading}
          style={{opacity: 0.5, padding: '0px 4px'}}
        />
      ) : (
        currentLength
      )}
    </span>
  );
  if (isMobile) {
    return (
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
        <Typography color={color}>
          {currentData} <em>/ </em>
          <span style={{marginLeft: 4}}>{`(${formattedTotalResults})`}</span>
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        color: color,
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      <span>{showingTxt}</span>
      <span>{currentData}</span>
      <span>{ofTxt}</span>
      <span>{`(${formattedTotalResults})`}</span>
      <span>{resultsTxt}</span>
      {forTitle && (
        <>
          <span>{forTxt}</span>
          <span>{`${unescape(forTitle)}`}</span>
        </>
      )}
    </Stack>
  );
};

SearchResultsCounter.propTypes = {
  request: PropTypes.bool,
  forTitle: PropTypes.string,
  totalResults: PropTypes.number,
  currentLength: PropTypes.number,
};
export default SearchResultsCounter;
