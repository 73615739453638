// get Career Favorites List
export const CAREER_FAVORITES_LIST_REQUEST = 'CAREER_FAVORITES_LIST_REQUEST';
export const CAREER_FAVORITES_LIST_RECEIVE = 'CAREER_FAVORITES_LIST_RECEIVE';
export const CAREER_FAVORITES_LIST_ERROR = 'CAREER_FAVORITES_LIST_ERROR';
export const CLEAR_CAREER_FAVORITES_LIST = 'CLEAR_CAREER_FAVORITES_LIST';

// get Program Favorites List
export const PROGRAM_FAVORITES_LIST_REQUEST = 'PROGRAM_FAVORITES_LIST_REQUEST';
export const PROGRAM_FAVORITES_LIST_RECEIVE = 'PROGRAM_FAVORITES_LIST_RECEIVE';
export const PROGRAM_FAVORITES_LIST_ERROR = 'PROGRAM_FAVORITES_LIST_ERROR';
export const CLEAR_PROGRAM_FAVORITES_LIST = 'CLEAR_PROGRAM_FAVORITES_LIST';

// get Program Enrollments List
export const PROGRAM_ENROLLMENT_LIST_REQUEST =
  'PROGRAM_ENROLLMENT_LIST_REQUEST';
export const PROGRAM_ENROLLMENT_LIST_RECEIVE =
  'PROGRAM_ENROLLMENT_LIST_RECEIVE';
export const PROGRAM_ENROLLMENT_LIST_ERROR = 'PROGRAM_ENROLLMENT_LIST_ERROR';
export const CLEAR_PROGRAM_ENROLLMENT_LIST = 'CLEAR_PROGRAM_ENROLLMENT_LIST';

// GoSurvey Results
export const GO_SURVEY_RESULTS_REQUEST = 'GO_SURVEY_RESULTS_REQUEST';
export const GO_SURVEY_RESULTS_RECEIVE = 'GO_SURVEY_RESULTS_RECEIVE';
export const GO_SURVEY_RESULTS_ERROR = 'GO_SURVEY_RESULTS_ERROR';
export const CLEAR_GO_SURVEY_RESULTS = 'CLEAR_GO_SURVEY_RESULTS';

// Enrolled List
export const ENROLLED_LIST_REQUEST = 'ENROLLED_LIST_REQUEST';
export const ENROLLED_LIST_RECEIVE = 'ENROLLED_LIST_RECEIVE';
export const ENROLLED_LIST_ERROR = 'ENROLLED_LIST_ERROR';
export const CLEAR_ENROLLED_LIST = 'CLEAR_ENROLLED_LIST';

// Enrolled overview
export const PROGRAM_DETAILS_REQUEST = 'PROGRAM_DETAILS_REQUEST';
export const PROGRAM_DETAILS_RECEIVE = 'PROGRAM_DETAILS_RECEIVE';
export const PROGRAM_DETAILS_ERROR = 'PROGRAM_DETAILS_ERROR';
export const CLEAR_PROGRAM_DETAILS = 'CLEAR_PROGRAM_DETAILS';

// recruitment insight Student Metrix
export const RECRUITMENT_INSIGHT_METRIX_REQUEST =
  'RECRUITMENT_INSIGHT_METRIX_REQUEST';
export const RECRUITMENT_INSIGHT_METRIX_RECEIVE =
  'RECRUITMENT_INSIGHT_METRIX_RECEIVE';
export const RECRUITMENT_INSIGHT_METRIX_ERROR =
  'RECRUITMENT_INSIGHT_METRIX_ERROR';
export const CLEAR_RECRUITMENT_INSIGHT_METRIX =
  'CLEAR_RECRUITMENT_INSIGHT_METRIX';
